<template>
  <div class="stats-item">
    <div class="no-wrap stats-item-top">
      <strong class="tw-text-4xl tw-font-black tw-leading-none"
        ><duration-item format="*h:mm" :duration="averageDuration"
      /></strong>
      <strong><i18n-t scope="global" keypath="Hours" /></strong>
    </div>
    <div class="tw-text-sm tw-leading-tight">
      <i18n-t scope="global" keypath="average per ride" />
    </div>
  </div>
  <div class="stats-item">
    <div class="tw-text-lg">
      <unit-item :value="averageDistance">
        <template #value="{ value }">
          <strong class="tw-text-4xl tw-font-black tw-leading-none">{{ value }}</strong>
        </template>

        <template #unit="{ unit }">
          <strong>{{ unit }}</strong>
        </template>
      </unit-item>
    </div>
    <div class="tw-text-sm tw-leading-tight">
      <i18n-t scope="global" keypath="average per ride" />
    </div>
  </div>
  <div v-if="timeSinceLastRide" class="stats-item">
    <div class="no-wrap stats-item-top">
      <strong class="tw-text-4xl tw-font-black tw-leading-none">{{ timeSinceLastRide }}</strong>
      <strong>{{ $t('days') }}</strong>
    </div>
    <div class="tw-text-sm tw-leading-tight">
      <i18n-t scope="global" keypath="since last ride" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DurationItem from './duration-item'
import UnitItem from './unit-item'

export default {
  name: 'UserStats',
  components: {
    DurationItem,
    UnitItem
  },
  data() {
    return {
      timeSinceLastRide: ref(null)
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  async created() {
    const trips = await this.$UserService.getUserTrips(this.user.id)
    const lastTrip = trips
      .sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      .shift()
    if (!lastTrip) {
      this.timeSinceLastRide = '∞'
      return
    }
    const timeSinceLastTrip = moment(lastTrip.date) - moment()
    const timeInDays = moment.duration(timeSinceLastTrip).asDays()
    const roundedAbsolute = Math.round(Math.abs(timeInDays))

    this.timeSinceLastRide = roundedAbsolute
  },
  computed: {
    averageDuration() {
      return this.user.total_time / this.user.trips_count || 0
    },
    averageDistance() {
      return this.user.total_distance / this.user.trips_count || 0
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.no-wrap
  white-space: nowrap

.large
  font-size: 36px
  margin-left: auto

.stats-item-top
  line-height: 0.9
  vertical-align: bottom

.stats-item-bottom
  line-height: 0.9
  vertical-align: top

.stats-item
  padding: 10px
  text-align: right
</style>
