<script setup lang="ts">
import useMainNavigation from '~/data/mainNavigation'

const authStore = useAuthStore()

const userId = computed(() => authStore.currentUser?.id)

const mainNavigation = userId.value ? useMainNavigation(userId.value.toString()) : []
</script>

<template>
  <div data-testid="navigation-main-menu">
    <NuxtLink
      v-for="navItem in mainNavigation"
      :key="navItem.translationKey"
      :to="navItem.route"
      class="tw-flex tw-items-center tw-gap-2"
    >
      <div class="icon tw-inline-block tw-w-6">
        <IconItem v-if="navItem.svgIconPath" :path="navItem.svgIconPath" />

        <FontAwesomeIcon v-else-if="navItem.fontAwesomeIcon" :icon="navItem.fontAwesomeIcon" />
      </div>

      <i18n-t scope="global" :keypath="navItem.translationKey" />
    </NuxtLink>
  </div>
</template>
