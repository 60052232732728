<template>
  <div class="tw-flex tw-min-h-screen tw-flex-col">
    <NavigationTopBar />

    <div
      class="tw-container tw-mx-auto tw-mb-6 tw-mt-6 tw-flex tw-flex-grow tw-gap-6 tw-px-4 md:tw-mt-16"
      style="max-width: 1400px"
      data-margin-top="70"
    >
      <div
        v-if="session_token"
        class="nav-bar-wrapper tw-hidden tw-w-56 tw-flex-shrink-0 md:tw-block"
      >
        <nav id="nav-bar" class="card tw-relative tw-pt-4">
          <div class="user-in-navbar -tw-mt-10 tw-flex tw-flex-col tw-pb-2">
            <div class="tw-mb-2">
              <router-link
                :to="`/user/${id}/timeline`"
                class="nav-userpic-link tw-relative tw-inline-flex tw-rounded-full"
              >
                <userpic-item class="nav-userpic tw-h-14 tw-w-14" />

                <UserTypeItem
                  class="tw-absolute -tw-right-2 -tw-top-2 tw-h-7 tw-w-7"
                  :account-type="user?.account_type"
                />
              </router-link>
            </div>

            <h2 class="tw-mb-1 tw-text-lg tw-font-semibold tw-leading-none tw-text-black/80">
              <username-item />
            </h2>

            <div class="tw-leading-none tw-text-black/90">
              <userbadge-item />
            </div>
          </div>

          <hr class="tw-my-2 tw-border-b-0 tw-border-t tw-border-black/5" />

          <div v-if="session_token" id="route-list" class="tw-px-4 tw-py-2">
            <NavigationMainMenu />
          </div>
        </nav>

        <user-stats :user="user" />
      </div>

      <div id="content" class="tw-w-full tw-flex-grow tw-overflow-hidden">
        <slot />
      </div>
    </div>

    <NavigationFooterBar />

    <NuxtLoadingIndicator />
  </div>
</template>

<script>
import UserpicItem from '../components/userpic-item'
import UsernameItem from '../components/username-item'
import UserbadgeItem from '../components/userbadge-item'
import UserStats from '~/components/user-stats'

export default {
  components: {
    UserbadgeItem,
    UsernameItem,
    UserpicItem,
    UserStats
  },
  data() {
    return {
      location: []
    }
  },
  computed: {
    session_token() {
      const authStore = useAuthStore(this.$pinia)

      return authStore.session_data.token
    },
    id() {
      const authStore = useAuthStore(this.$pinia)

      return authStore.currentUser?.id
    },
    user() {
      const authStore = useAuthStore(this.$pinia)

      return authStore.currentUser
    }
  },
  async created() {
    const geoip = await this.$GeoService.getGeoIP()
    this.location = geoip
  }
}
</script>

<style scoped>
#route-list a {
  @apply tw-flex tw-items-center;
}

#route-list a .icon {
  @apply tw-mr-2 tw-flex tw-w-6 tw-items-center tw-justify-center;
}
</style>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.nav-bar-wrapper
  height: fit-content
  position: sticky
  top: calc(#{$top-bar-height} + 48px)

  >:not(:first-child)
    margin-top: $defaultMargin

#nav-bar
  box-sizing: border-box
  padding-bottom: $padding
.user-in-navbar
  text-align: center
  .nav-userpic
    width: 50px
    height: 50px
    border: 2px solid $gray

.nuxt-link-active
  font-weight: normal

.nuxt-link-exact-active
  font-weight: bold
// TODO solve dark-mode issues on adroid
@media (prefers-color-scheme: dark)
//   .icon
//      filter: invert(1)
</style>
